// src/ApiClient.ts

/**
 * A simple API client for making HTTP requests.
 */
// ApiClient.ts

/**
 * A simple API client for making HTTP requests.
 */
export class ApiClient {
  private baseURL: string = process.env.NODE_ENV === "development" ? 'http://localhost:3000' : 'https://api.unghoster.com'

  /**
   * Initializes the ApiClient with a base URL if necessary.
   * @param baseURL - The base URL for the API endpoints.
   */
  constructor(baseURL?: string) {
    if(baseURL){
      this.baseURL = baseURL;
    }
  }

  /**
   * Makes an HTTP request to the API.
   * @param endpoint - The API endpoint path.
   * @param method - The HTTP method (GET, POST, PUT, DELETE).
   * @param data - The request payload.
   * @param headers - Additional request headers.
   * @returns - The response data.
   */
  async request(
    endpoint: string,
    method: string = 'GET',
    data: object | null = null,
    headers: Record<string, string> = {}
  ): Promise<any> {
    const options: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Request-URL': window.location.href,
        ...headers,
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(`${this.baseURL}${endpoint}`, options);

      if (!response.ok) {
        //const errorData = await response.json();
        //throw new Error(errorData.message || 'Something went wrong');
      }

      return await response.json();
    } catch (error: any) {
      if (error.message !== 'blocked domain') {
        console.error('error while fetching:', error);
      }
      //throw error;
    }
  }

  /**
   * Makes a GET request.
   * @param endpoint - The API endpoint path.
   * @param headers - Additional request headers.
   * @returns - The response data.
   */
  get(endpoint: string, headers: Record<string, string> = {}): Promise<any> {
    return this.request(endpoint, 'GET', null, headers);
  }

  /**
   * Makes a POST request.
   * @param endpoint - The API endpoint path.
   * @param data - The request payload.
   * @param headers - Additional request headers.
   * @returns - The response data.
   */
  post(endpoint: string, data: object, headers: Record<string, string> = {}): Promise<any> {
    return this.request(endpoint, 'POST', data, headers);
  }

  /**
   * Makes a PUT request.
   * @param endpoint - The API endpoint path.
   * @param data - The request payload.
   * @param headers - Additional request headers.
   * @returns - The response data.
   */
  put(endpoint: string, data: object, headers: Record<string, string> = {}): Promise<any> {
    return this.request(endpoint, 'PUT', data, headers);
  }

  /**
   * Makes a DELETE request.
   * @param endpoint - The API endpoint path.
   * @param headers - Additional request headers.
   * @returns - The response data.
   */
  delete(endpoint: string, headers: Record<string, string> = {}): Promise<any> {
    return this.request(endpoint, 'DELETE', null, headers);
  }
}
