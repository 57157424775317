
import { ApiClient } from '../utils/ApiClient';

/**
 * Retrieves IP information of the visitor.
 * @returns The IP information.
 */
export const getIpInfo = async (): Promise<any> => {
    try {
    const ipClient = new ApiClient('https://ipinfo.io/json');
    const ipInfo = await ipClient.get('');
    const ip : string = ipInfo.ip;

    const apiClient = new ApiClient('https://ipinfo.io')
    
    return await apiClient.get(`/widget/demo/${ip}`)
    
    } catch(err) {
    
    }
};
  