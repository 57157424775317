import { ApiClient } from "../utils/ApiClient";
import { getCookie, setCookie } from "../utils/cookies";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

/**
 * Retrieves or creates a visitor ID.
 * @param pixelId - The tracking pixel ID.
 * @param ipInfo - The visitor's IP information.
 * @returns The visitor ID.
 */
export const getOrCreateBrowserSessionId = async (
  pixelId: string,
  ipInfo: any
): Promise<string> => {
  const apiClient = new ApiClient();

  // First, clean up any existing "undefined" values
  if (getCookie("unghoster_browser_session_id") === "undefined") {
    setCookie("unghoster_browser_session_id", "", -1); // Remove the cookie
  }
  if (localStorage.getItem("unghoster_browser_session_id") === "undefined") {
    localStorage.removeItem("unghoster_browser_session_id");
  }

  let browser_session_id: string | null = null;
  const cookieValue = getCookie("unghoster_browser_session_id");
  const localStorageValue = localStorage.getItem(
    "unghoster_browser_session_id"
  );

  // Only use values that are neither null, undefined, nor "undefined"
  if (cookieValue && cookieValue !== "undefined") {
    browser_session_id = cookieValue;
  } else if (localStorageValue && localStorageValue !== "undefined") {
    browser_session_id = localStorageValue;
  }

  // If we have a valid session ID in localStorage but not in cookies, sync it
  if (!cookieValue && browser_session_id) {
    setCookie("unghoster_browser_session_id", browser_session_id, 730); // Set cookie for 2 year
  }

  // If we have a valid session ID, use it
  if (browser_session_id && browser_session_id !== "undefined") {
    return browser_session_id;
  }

  // Otherwise, create a new visitor
  const fp = await FingerprintJS.load();
  const fingerprint = await fp.get();

  // Create new visitor
  const visitorData: BrowserSessionData = await apiClient.post(
    `/pixel/${pixelId}/session`,
    {
      ipInfo,
      f_id: fingerprint.visitorId,
    }
  );

  if (
    visitorData.browser_session_id &&
    visitorData.browser_session_id !== "undefined"
  ) {
    setCookie(
      "unghoster_browser_session_id",
      visitorData.browser_session_id,
      3000
    );
    localStorage.setItem(
      "unghoster_browser_session_id",
      visitorData.browser_session_id
    );
    return visitorData.browser_session_id;
  } else {
    throw new Error("Invalid browser session ID received from server");
  }
};

/**
 * Records a visitor's activity on the website.
 * @param pixelId - The tracking pixel ID.
 * @param browser_session_id - The browser_session_id.
 */
export const createSessionEvent = async (
  pixelId: string,
  browser_session_id: string
) => {
  if (!browser_session_id || browser_session_id === "undefined") {
    throw new Error("Invalid browser session ID");
  }

  const apiClient = new ApiClient();
  await apiClient.post(`/pixel/${pixelId}/session-event`, {
    browser_session_id,
    url: window.location.href,
    title: document.title,
  });
};

/**
 * Checks if localStorage is enabled.
 * @throws Will throw an error if localStorage is disabled.
 */
export const checkStorage = () => {
  try {
    const testKey = "unghoster_browser_local_storage";
    const testValue = "unghoster_browser_local_storage_token";
    localStorage.setItem(testKey, testValue);
    const storedValue = localStorage.getItem(testKey);
    if (storedValue !== testValue) {
      throw new Error("browser local storage error");
    }
    localStorage.removeItem(testKey);
  } catch {
    throw new Error("browser local storage disabled");
  }
};
