// src/unghoster.ts

import { getIpInfo } from "./tracking/getIpInfo";
import { manualVerify } from "./tracking/manualVerify";
import {
  getPixelConfiguration,
  injectFCTrackingScript,
  injectTrackingScript,
} from "./tracking/personID";
import {
  checkStorage,
  getOrCreateBrowserSessionId,
  createSessionEvent,
} from "./tracking/visitor";
import "whatwg-fetch";
import { LoadOptions } from "./utils/LoadOptions";

type QueueItem = [keyof Unghoster, any[]];

const hostingProviders = [
  "aws.com",
  "amazon.com",
  "microsoft.com",
  "google.com",
  "ibm.com",
  "oracle.com",
  "digitalocean.com",
  "alibaba.com",
  "linode.com",
  "vultr.com",
  "ovhcloud.com",
  "apple.com",
];

/**
 * Represents the Unghoster tracking object.
 */
class Unghoster {
  pixelId: string | null;
  options: LoadOptions | null;
  browser_session_id: string | null;
  eventHandlers: { [key: string]: Function[] };
  private lastUrl: string;
  queue: QueueItem[]; // reverse compatibility

  constructor() {
    this.pixelId = null;
    this.browser_session_id = null;
    this.options = null;
    this.eventHandlers = {};
    this.lastUrl = window.location.href;
    this.queue = []; // reverse compatibility

    // Process any existing queue from old snippet
    const existingInstance = (window as any).unghoster;
    if (existingInstance && existingInstance.queue) {
      this.queue = existingInstance.queue;
    }

    this._setupPageChangeDetection();
    this._checkForTrackingConfirmationCode();
    this._processOldQueue(); // reverse compatibility
  }

  /**
   * Process queued commands from old snippet
   */
  private async _processOldQueue() {
    while (this.queue && this.queue.length) {
      const [method, args] = this.queue.shift()!;
      // Type assertion to ensure method is callable
      const func = this[method] as Function;
      if (typeof func === "function") {
        await func.apply(this, args);
      }
    }
  }

  /**
   * Sets up detection for page changes.
   */
  private _setupPageChangeDetection() {
    // Listen for changes in the URL
    window.addEventListener("popstate", () => this._onPageChange());

    // Check for URL changes periodically (for SPA routing)
    setInterval(() => {
      if (window.location.href !== this.lastUrl) {
        this._onPageChange();
      }
    }, 500);
  }

  /**
   * Handles page change events.
   */
  private async _onPageChange() {
    this.lastUrl = window.location.href;
    if (this.pixelId && this.browser_session_id) {
      await this._createPageChangeActivity();
    }
    await this._checkForTrackingConfirmationCode();
  }

  /**
   * Creates a visitor activity for page changes.
   */
  private async _createPageChangeActivity() {
    try {
      await createSessionEvent(this.pixelId!, this.browser_session_id!);
    } catch (error) {
      console.error(
        "An error occured while creating session page event:",
        error
      );
    }
  }

  /**
   * Loads the Unghoster tracking pixel.
   * @param pixelId - The tracking pixel ID.
   */
  async load(pixelId: string, options: LoadOptions = {}) {
    try {
      this.pixelId = pixelId;
      this.options = options;
      await this._checkForTrackingConfirmationCode();
      checkStorage();

      const ipInfo = await getIpInfo();

      const browser_session_id = await getOrCreateBrowserSessionId(
        pixelId,
        ipInfo
      );
      this.browser_session_id = browser_session_id;

      if (!browser_session_id) {
        return;
      }

      await createSessionEvent(pixelId, browser_session_id);

      if (
        ipInfo?.data?.company?.domain &&
        hostingProviders.includes(ipInfo.data.company.domain)
      ) {
        return;
      }

      const pixel = await getPixelConfiguration(pixelId);

      // p-level id enabled and visitor geolocated in US only
      if (
        pixel?.configuration.isPersonLevelEnabled &&
        !pixel.configuration.maxUsageReached
      ) {
        if (pixel.configuration.personLevelIdType === "fc") {
          //&&this._isUSVisitor(ipInfo)){
          injectFCTrackingScript(
            browser_session_id,
            `https://api.unghoster.com/pixel/${pixelId}/session/identify`
          );
        } else {
          injectTrackingScript(
            browser_session_id,
            `https://api.unghoster.com/pixel/${pixelId}/session/identify`,
            `https://api.unghoster.com/pixel/${pixelId}/session/identify/error`
          );
        }
      }
    } catch (error: any) {
      if (error.message !== "blocked domain") {
        console.error("error while loading:", error);
      }
    }
  }

  /*
    Make sure the visitor is from USA
  */
  private _isUSVisitor(ipInfo: any) {
    return ipInfo?.["data"]?.["country"] === "US";
  }

  /**
   * Checks for the unghoster_tracking_confirmation_code parameter in the URL.
   */
  private async _checkForTrackingConfirmationCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationCode = urlParams.get(
      "unghoster_tracking_confirmation_code"
    );
    if (confirmationCode && confirmationCode === this.pixelId) {
      await manualVerify(confirmationCode);
    }
  }
}

// Expose the Unghoster instance to the global scope
const unghosterInstance = new Unghoster();
export default unghosterInstance;

if ((window as any).unghosterPixelId) {
  const pixelId = (window as any).unghosterPixelId;
  const personId = (window as any).unghosterPersonId; // Optional
  (window as any).unghoster.load(pixelId, { personId });
}
