import { ApiClient } from "../utils/ApiClient";

/**
 * Manual Verification of the pixel
 * @param pixelId - The tracking pixel ID.
 */
export const manualVerify = async (pixelId: string) => {
  const apiClient = new ApiClient(); 
  await apiClient.post(`/pixel/${pixelId}/verify/manual`, {
    url: window.location.href,
    title: document.title,
  });
};

