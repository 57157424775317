import { parse, serialize } from 'cookie';

// Get cookie by name
export const getCookie = (name: string): string | undefined => {
  const cookies = parse(document.cookie);  // Parse the document.cookie string into an object
  return cookies[name];
};

// Set a cookie
export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5);  // Calculate expiration date
  const serializedCookie = serialize(name, value, {
    expires,
    path: '/',
    domain: window.location.hostname,
    secure: true,
    sameSite: 'none',
  });
  
  document.cookie = serializedCookie;  // Set the cookie
};