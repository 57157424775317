// @ts-nocheck

import { ApiClient } from "../utils/ApiClient";

/**
 * Defines the structure of the external tracking object.
 */
interface LiqdDid006x {
  resolve: (
    successCallback: (result: any) => void,
    errorCallback: (error: any) => void,
    options: { resolve: string[] }
  ) => void;
}

/**
 * Injects the tracking script with a specified ID and sends results/errors to backend API endpoints.
 * @param trackingId - The tracking ID to use
 * @param resultEndpoint - Backend endpoint to receive the result
 * @param errorEndpoint - Backend endpoint to receive errors
 */
export function injectTrackingScript(
  visitorId: string,
  resultEndpoint: string,
  errorEndpoint: string
) {
  (window as any)["liQd_did_006x"] = (window as any)["liQd_did_006x"] || [];
  const script = document.createElement("script");

  script.src = "https://d-code.liadm.com/did-006x.min.js";
  script.onload = () => {
    const trackingObject = (window as any)["liQd_did_006x"] as
      | LiqdDid006x
      | undefined;
    trackingObject?.resolve(
      (result: any) => {
        if (result && Object?.keys(result)?.length > 0) {
          fetch(resultEndpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ type: "li", visitorId, result }),
          }).catch(console.error);
        }
      },
      (err: any) => {
        // Send error to backend
        if (err) {
          fetch(errorEndpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ visitorId, error: err }),
          }).catch(console.error);
        }
      },
      { resolve: ["md5", "sha2", "nonId"] }
    );
  };

  document.head.appendChild(script);
}

/**
 * Injects the tracking script with a specified ID and sends results/errors to backend API endpoints.
 * @param trackingId - The tracking ID to use
 * @param resultEndpoint - Backend endpoint to receive the result
 * @param errorEndpoint - Backend endpoint to receive errors
 */
export function injectFCTrackingScript(
  visitorId: string,
  resultEndpoint: string,
  pixelId?: string
) {
  (function (w, d, s, o, f, js, fjs) {
    // @ts-ignore
    w["FCObject"] = o;
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
    // @ts-ignore
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    // @ts-ignore
    js.id = o;
    js.src = f;
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
  })(
    window,
    document,
    "script",
    "fc",
    "https://tags.fullcontact.com/anon/fullcontact.js"
  );

  var config = {
    storageStrategy: "cookie",
    trackActivities: true,
    // @ts-ignore
    callback: (pid) => {
      fetch(resultEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          type: "fc",
          visitorId,
          result: {
            pid: pid,
          },
        }),
      }).catch(console.error);
    },
  };

  // @ts-ignore
  fc("init", pixelId ?? "YNQkn8MdixPcZ0PnzcfElDP6eMq020hf", config);
}

/**
 * Retrieves a pixel configuration.
 * @param pixelId - The tracking pixel ID.
 * @returns The pixel ID and configuration.
 */
export const getPixelConfiguration = async (
  pixelId: string
): Promise<PixelConfiguration | undefined> => {
  const apiClient = new ApiClient();

  const pixelConfiguration: PixelConfiguration | undefined =
    await apiClient.get(`/pixel/${pixelId}/configuration`);
  return pixelConfiguration;
};
